/* eslint-disable prettier/prettier */
import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import Settings from "../../settings"
import AccountInfo from "../../Profile/AccountInfo";
import Fetcher from "../../Common/Fetch";
import { PaymentLotStatus } from "../types";
import {
    Nullable,
    ILotModel,
    IModelError,
    TradeStates,
    ISelectListItem,
    ILotEditorModel,
    IErrorDictionary,
    TradeStartTypes,
    IDimensions,
    IDeliveryStartPoint,
    IDeliveryData,
} from "../../Common/types";

/**
   @interface IPropsType - интерфейс предоставляющий некоторые поля необходимые компоненту PopupBuyNow для его работы. 
   @property lotId - идентификатор лота.
*/

interface IPropsType {
    lotId: number,
    isTradeEnd: false,
    shippingMethod: string,
    mithraTheme: string
}

interface PayByQRcode {
    Img: string,
    Name: string,
    BankName: string,
    PersonalAcc: string,
    BIC: string,
    CorrespAcc: string,
    PayeeINN: string,
    KPP: string,
    Purpose: string,
    SumRub: number,
    CurrentStep: number
}

interface IExtraLotEditorModel extends ILotEditorModel {
    DeliveryDimensions: IDimensions,
    DeliveryStartPoint: IDeliveryStartPoint
}

/**
   @function PopupBuyNow - функциональный компонет представляющий из себя выпадающий попап, который позволяет произвести оплату лота
   попап открывается при клике на кнопку "Купить сейчас" при просмотре какого-либо лота
*/
function PopupBuyNow(props: IPropsType) {

    // свойство которое хранит в себе шаг покупки, т.е. при шаге 1 показывается одно диалоговое окно, при шаге 2 другое.
    const [step, setStep] = useState(-1);

    // свойство которое хранит в себе данные авторизации на шаге 1
    const [formLogin, setFormLogin] = useState({ email: '', password: '' });

    const [isRememberMeState, changeRememberMeState] = useState(false);

    const [commission, setCommission] = useState('');

    const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(false);

    const [payQRcode, setPayQRcode] = useState<PayByQRcode>({
        Img: "",
        Name: "",
        BankName: "",
        PersonalAcc: "",
        BIC: "",
        CorrespAcc: "",
        PayeeINN: "",
        KPP: "",
        Purpose: "",
        SumRub: 1,
        CurrentStep: 3
    });

    const [dimensions, setDimensions] = useState<IDimensions>();
    const [deliveryStartPoint, setDeliveryStartPoint] = useState<IDeliveryStartPoint>();

    const [errorPay, setErrorPay] = useState("");

    const [token, setToken] = useState("");


    //Получение данных СДЭК доставки
    useEffect(() => {
        const getDimensions = async () => {
            try {
                const response = await Fetcher(`/TradeEditApi/GetDeliveryDetails?lotId=${props.lotId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(`Данные о доставке для лота ${props.lotId}:\n${JSON.stringify(data)}`);
                console.log(`Тип данных ${typeof data.UpdatedItem}`);

                let dimensionsObject;
                if (typeof data.UpdatedItem === 'string') {
                    dimensionsObject = JSON.parse(data.UpdatedItem);
                }

                console.log("DimensionsObject", dimensionsObject);

                if (dimensionsObject) {
                    setDimensions({
                        Width: dimensionsObject.Width,
                        Height: dimensionsObject.Height,
                        Length: dimensionsObject.Length,
                        Weight: dimensionsObject.Weight
                    });

                    setDeliveryStartPoint({
                        City: dimensionsObject.FromCity,
                        Address: dimensionsObject.FromAddress,
                        CountryCode: 'RU'
                    });
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }

        getDimensions();
    }, [])

    //Заносит в переменную процент комиссии в зависимости от сайта
    useEffect(() => {
        console.log('ТЕМА:' + props.mithraTheme, Settings.Data);
        if (props.mithraTheme === 'MitraRealty' || props.mithraTheme === 'MitraChina') {
            setCommission("15%");
        } else if (props.mithraTheme === 'MitraArt') {
            setCommission("3%");
        }
    }, [])

    useEffect(() => {
        if (errorPay) {
            console.log('ErrorPay has been set:', errorPay);
        }
    }, [errorPay]);

    // метод вызывается при клике по кнопке "Купить сейчас""
    const openStart = () => {
        console.log('PopupBuyNow lotId = ' + props.lotId);
        console.log('AccountInfo.Id = ' + AccountInfo.Id);

        console.log('SHIPPINGMETHOD:' + props.shippingMethod);

        console.log(AccountInfo.PaymentLots);

        var isStepPay = false;


        // если пользователь не авторизован
        if (AccountInfo.Id == undefined || AccountInfo.Id == 0) {
            setStep(0);
            console.log('step 0');
            setTimeout(() => {
                setStep(2);
            }, 3000);
            return;
        }


        // проверим в оплаченных лотах пользователя, нет ли данного лота и какой у него статус оплаты
        if (AccountInfo.PaymentLots != undefined) {
            AccountInfo.PaymentLots.forEach(function (pay) {
                console.log(pay);
                if (pay.LotId == props.lotId && pay.StatusId == PaymentLotStatus.PaidComission)
                    isStepPay = true;
            });
        }

        if (isStepPay) {
            setStep(4);
        }
        else {
            setStep(3);
        }
    }

    const closeZero = () => {
        if (AccountInfo.Id == undefined || AccountInfo.Id == 0) {
            setStep(2);
        } else {
            setStep(1);
        }
    }



    // метод вызывается при клике по кнопке "Зарегистрироваться". Сама кнопка располагается в попапе на нулевом шаге 
    const openRegisterForm = () => {
        console.log('openRegisterForm');
        const url = window.location.origin + "/Account/Register";
        console.log(url);
        window.open(url.toString(), '_blank');
    }

    // данный метод обрабатывает ввод в поле email на форме авторизации - шаг 1
    const handleLoginEmailChange = (evnt) => {
        var data = formLogin;
        formLogin.email = evnt.target.value;
        setFormLogin(formLogin);
    }

    // данный метод обрабатывает ввод в поле password на форме авторизации - шаг 1
    const handlePasswordChange = (evnt) => {
        var data = formLogin;
        formLogin.password = evnt.target.value;
        setFormLogin(formLogin);
    }

    // данный метод вызывается при клике по копке "Войти" на форме авторизации в попапе - шаг 1
    const LogIn = () => {
        if (!formLogin.email || !formLogin.password) {
            alert('Пожалуйста, заполните все поля для входа.');
            return;
        }

        //Логика входа
        handleLogin();
    }

    // данный метод выполняет запрос к серверу на атворизацию пользователя
    const handleLogin = async () => {
        const token = await getAntiforgeryToken();
        Fetcher('/auth/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'RequestVerificationToken': token
            },
            body: JSON.stringify({
                UserName: formLogin.email,
                Password: formLogin.password
            })
        })
            .then(response => response.json())
            .then(data => {
                setStep(3);
                window.location.reload();
            });
    }

    //Получить токен для атворизации пользователя
    const getAntiforgeryToken = async () => {
        const tokenResponse = await Fetcher('/auth/getAntiForgeryToken', {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const token = await tokenResponse.json();
        console.log(JSON.stringify(token));
        return token;
    }

    const rememberMeChange = () => {
        isRememberMeState ? changeRememberMeState(false) : changeRememberMeState(true);
    }

    // метод оплаты комиссии
    const payComission = () => {
        const url = window.location.origin + "/TradeEditApi/PaymentComission?lotId=" + props.lotId;
        console.log(url);
        window.open(url.toString(), '_blank');
        setStep(4);
    }

    // метод оплаты комиссии через QR-код
    const payQRComission = () => {
        if (payQRcode.Img.length == 0 || payQRcode.CurrentStep != step) {
            const qrurl = window.location.origin + "/TradeEditApi/PaymentComissionByQRCode?lotId=" + props.lotId;
            Fetcher(qrurl,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if (result.Success) {
                            let jsonObject = JSON.parse(result.Requisite);
                            jsonObject.Img = result.Image;
                            console.log(jsonObject);
                            setPayQRcode(jsonObject);
                        }
                        else {
                            console.log(result.Error);
                            setErrorPay(result.Error);
                        }
                    }
                )
                .catch((exception) => {
                    console.log('payQRComission catch: ', exception);
                    setStep(999);
                });
        }
        setStep(31);
    }

    // метод оплаты лота
    const payLot = () => {
        const url = window.location.origin + "/TradeEditApi/PaymentLot?lotId=" + props.lotId;
        console.log(url);
        window.open(url.toString(), '_blank');
        setStep(5);
        setTimeout(() => {
            setStep(6);
        }, 2000)
    }

    // метод оплаты лота через QR-код
    const payQRLot = () => {
        if (payQRcode.Img.length == 0 || payQRcode.CurrentStep != step) {
            const url = window.location.origin + "/TradeEditApi/PaymentLotByQRCode?lotId=" + props.lotId;
            Fetcher(url,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if (result.Success) {
                            let jsonObject = JSON.parse(result.Requisite);
                            jsonObject.Img = result.Image;
                            console.log(jsonObject);
                            setPayQRcode(jsonObject);
                        }
                        else {
                            console.log(result.Error);
                            setErrorPay(result.Error);
                        }
                    }
                )
                .catch((exception) => {
                    console.log('payQR catch: ', exception);
                    setStep(999);
                });
        }
        setStep(41);
    }

    // проверка отплаты комиссии с qr-кода
    const check_payQRComission = () => {
        // проверка отплаты комиссии с qr-кода
        // ...
        setStep(4);
        setPayQRcode({
            Img: "",
            Name: "",
            BankName: "",
            PersonalAcc: "",
            BIC: "",
            CorrespAcc: "",
            PayeeINN: "",
            KPP: "",
            Purpose: "",
            SumRub: 1,
            CurrentStep: 3
        });
    }

    // проверка отплаты лота с qr-кода
    const check_payQRLot = () => {
        // проверка отплаты лота с qr-кода
        // ...
        setStep(5);
        setPayQRcode({
            Img: "",
            Name: "",
            BankName: "",
            PersonalAcc: "",
            BIC: "",
            CorrespAcc: "",
            PayeeINN: "",
            KPP: "",
            Purpose: "",
            SumRub: 1,
            CurrentStep: 3
        });
    }

    useEffect(() => {
        const getUserLocation = () => {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const userLocation = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            console.log('User location:', userLocation);
                            resolve(userLocation);
                        },
                        (error) => {
                            console.error('Ошибка получения местоположения пользователя:', error);
                            reject(error);
                        }
                    );
                } else {
                    console.error('Geolocation is not supported by this browser.');
                    reject(new Error('Geolocation not supported'));
                }
            });
        };

        const initializeCDEKWidget = (userLocation) => {
            const CDEKWidget = (window as any).CDEKWidget;
            if (CDEKWidget) {
                const widget = new CDEKWidget({
                    from: {
                        country_code: deliveryStartPoint.CountryCode,
                        city: deliveryStartPoint.City,
                        postal_code: 630009,
                        code: 270,
                        address: deliveryStartPoint.Address,
                    },
                    to: {
                        latitude: userLocation ? userLocation.lat : null,
                        longitude: userLocation ? userLocation.lng : null
                    },
                    center: userLocation ? {
                        latitude: userLocation.lat,
                        longitude: userLocation.lng
                    } : null,
                    zoom: 10,
                    root: 'cdek-map',
                    apiKey: '49ea5682-b2de-4233-86e3-e009cff7dfb4',
                    canChoose: true,
                    servicePath: 'https://art.viomitra.ru/service.php',
                    hideFilters: {
                        have_cashless: false,
                        have_cash: false,
                        is_dressing_room: false,
                        type: false,
                    },
                    hideDeliveryOptions: {
                        office: false,
                        door: false,
                    },
                    debug: false,
                    goods: [
                        {
                            width: dimensions.Width,
                            height: dimensions.Height,
                            length: dimensions.Length,
                            weight: dimensions.Weight,
                        },
                    ],
                    defaultLocation: userLocation ? [userLocation.lng, userLocation.lat] : 'Россия',
                    lang: 'rus',
                    currency: 'RUB',
                    tariffs: {
                        office: [234, 136, 138],
                        door: [233, 137, 139],
                    },
                    onReady() {
                        console.log('Виджет загружен');
                    },
                    onCalculate(data) {
                        console.log('Расчет стоимости доставки произведен' + JSON.stringify(data, null, 2));
                    },
                    onChoose(data) {
                        console.log('Доставка выбрана' + data);
                    },
                    // Если у нас есть координаты пользователя, добавим их здесь
                    ...(userLocation ? { to: { latitude: userLocation.lat, longitude: userLocation.lng } } : {}),
                });
            }
        };

        if (deliveryStartPoint) {
            console.log(dimensions);
            console.log(deliveryStartPoint);

            // Попробуем получить местоположение пользователя
            getUserLocation()
                .then(userLocation => {
                    // Если получено местоположение, инициализируем виджет с ним
                    initializeCDEKWidget(userLocation);
                })
                .catch(error => {
                    // Если не удалось получить местоположение, инициализируем виджет без местоположения
                    console.log('Использование виджета без местоположения пользователя. Ошибка:', error);
                    initializeCDEKWidget(null);
                });
        }
    }, [deliveryStartPoint]);

    useEffect(() => {
        const getDimensions = async () => {
            try {
                const response = await Fetcher(`/TradeEditApi/GetDeliveryDetails?lotId=${props.lotId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(`Данные о доставке для лота ${props.lotId}:\n${JSON.stringify(data)}`);
                console.log(`Тип данных ${typeof data.UpdatedItem}`);

                let dimensionsObject;
                if (typeof data.UpdatedItem === 'string') {
                    dimensionsObject = JSON.parse(data.UpdatedItem);
                }

                console.log("DimensionsObject", dimensionsObject);

                if (dimensionsObject) {
                    setDimensions({
                        Width: dimensionsObject.Width,
                        Height: dimensionsObject.Height,
                        Length: dimensionsObject.Length,
                        Weight: dimensionsObject.Weight
                    });

                    setDeliveryStartPoint({
                        City: dimensionsObject.FromCity,
                        Address: dimensionsObject.FromAddress,
                        CountryCode: 'RU'
                    });
                }

                setIsDeliveryAvailable(dimensionsObject.isDeliveryAvailable === true);
                if (isDeliveryAvailable) {
                    console.log("Доставка доступна для этого лота");
                } else {
                    console.log("Доставка недоступна для этого лота");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        getDimensions();
    }, []);


    //Получение токена
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const response = await fetch("https://api.edu.cdek.ru/v2/oauth/token", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        grant_type: "client_credentials",
                        client_id: "PPa4lbyK7PP9uiHhCOFZ5uka1jHW2Sf8",
                        client_secret: "gd38zcuTp6nHgTYi4LDVlNymRuTmh0K3",
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setToken(data.access_token);
                    console.log("Token:", data.access_token);
                } else {
                    console.error("Ошибка получения токена:", response.statusText);
                }
            } catch (error) {
                console.error("Ошибка сети:", error);
            }
        };

        fetchToken();
    }, []);

    useEffect(() => {
        if (deliveryStartPoint) {
            console.log(dimensions);
            console.log(deliveryStartPoint);

            const CDEKWidget = (window as any).CDEKWidget;
            if (CDEKWidget) {
                const widget = new CDEKWidget({
                    from: {
                        country_code: deliveryStartPoint.CountryCode,
                        city: deliveryStartPoint.City,
                        postal_code: 630009,
                        code: 270,
                        address: deliveryStartPoint.Address,
                    },
                    root: 'cdek-map',
                    apiKey: '49ea5682-b2de-4233-86e3-e009cff7dfb4',
                    canChoose: true,
                    servicePath: 'https://art.viomitra.ru/service.php',
                    hideFilters: {
                        have_cashless: false,
                        have_cash: false,
                        is_dressing_room: false,
                        type: false,
                    },
                    hideDeliveryOptions: {
                        office: false,
                        door: false,
                    },
                    debug: false,
                    goods: [
                        {
                            width: dimensions.Width,
                            height: dimensions.Height,
                            length: dimensions.Length,
                            weight: dimensions.Weight,
                        },
                    ],
                    defaultLocation: 'Россия',
                    lang: 'rus',
                    currency: 'RUB',
                    tariffs: {
                        office: [234, 136, 138],
                        door: [233, 137, 139],
                    },
                    onReady() {
                        console.log('Виджет загружен');
                    },
                    onCalculate(data) {
                        console.log('Расчет стоимости доставки произведен', JSON.stringify(data, null, 2));
                    },
                    onChoose(data) {
                        console.log('Доставка выбрана', data);
                        handleOrderPlacement(data); // Функция для оформления заказа
                    },
                });
            }
        }
    }, [deliveryStartPoint]);

    // Функция оформления заказа через API
    const handleOrderPlacement = async (chosenPickupPoint) => {
        console.log('Оформление заказа');

        try {
            const orderData = {
                tariff_code: chosenPickupPoint.tariffCode,
                from_location: {
                    code: 270,
                    city: deliveryStartPoint.City,
                    address: deliveryStartPoint.Address,
                    country_code: 'RU',
                    postal_code: '630009',
                },
                to_location: {
                    code: chosenPickupPoint.code,
                    address: chosenPickupPoint.address,
                    country_code: 'RU',
                },
                packages: [
                    {
                        weight: dimensions.Weight,
                        length: dimensions.Length,
                        width: dimensions.Width,
                        height: dimensions.Height,
                    }
                ],
                services: [
                    // Нужные сервисы (например, страховка и т.д.), если необходимо
                ],
                recipient: {
                    name: 'Имя Получателя', // Нужно заменить на реальные данные покупателя (@peep0001)
                    phones: [
                        {
                            number: '+70000000000', // Нужно указать телефон покупателя (@peep0001)
                        }
                    ],
                },
            };

            const response = await fetch('https://api.edu.cdek.ru/v2/orders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `"Bearer" ${token}`,
                },
                body: JSON.stringify(orderData),
            });

            if (!response.ok) {
                throw new Error('Ошибка создания заказа');
            }

            const orderResponse = await response.json();
            console.log('Заказ успешно создан', orderResponse);

            // Дополнительные действия после создания заказа (например, уведомление покупателя)
        } catch (error) {
            console.error('Ошибка при создании заказа:', error);
        }
    };



    return (
        <React.Fragment>
            {/** кнопка "Купить сейчас" */}
            <div className="col">
                <button className="btn btn-primary btn-font-14 btn-lg btn-block px-10 btn-sm-mobile"
                    onClick={openStart} style={{ border: 'none' }}>
                    {props.isTradeEnd ? 'Сформировать документы' : 'Купить сейчас'}
                </button>
            </div>

            {/** диалоговые окна */}
            <div className="popup-container" style={{ display: step >= -1 ? 'block' : 'none', overflowY: 'auto', maxHeight: '75vh' }}>
                {/** диалоговое окно нулевого шага */}
                {step == 0 && <div className="wrap-zero">
                    <div className="close-btn">
                        <button className="closeBtn-log-in" type="button" onClick={closeZero}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#690097"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                        </button>
                    </div>
                    <h2>Внимание! <hr></hr> </h2>
                    <p>Победитель торгов оплачивает&nbsp; {commission} от&nbsp;цены лота при успешной сделке</p>
                </div>}

                {step != 0 && <div className="close-btn">
                    <button onClick={() => { setStep(-1) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1C2C9C"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </button>
                </div>}

                {/** диалоговое окно первого шага - просьба авторизоваться если пользователь не авторизован */}
                {(AccountInfo.Id == undefined || AccountInfo.Id == 0) && step == 1 &&
                    <div className="wrap-step wrap-step1">
                        <h1 className="header-text-cl-kk">Внимание: завершающий этап покупки! <hr></hr></h1>
                        <div className="Balls-close">
                            <span className="ball-1"></span>
                            <span className="line-1"></span>
                            <span className="ball-2"></span>
                            <span className="line-2"></span>
                            <span className="ball-3"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Оплата комиссии</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Оплата лота</p>
                            </div>
                        </div>
                        <div className="center">
                            <button className="log-in-cl-kk" onClick={() => { setStep(2) }}>Войти</button>
                            <button className="sign-in-cl-kk" onClick={openRegisterForm}>Зарегистрироваться</button>
                        </div>
                        <p className="downblock-text-kk">Пожалуйста, войдите в личный кабинет, чтобы совершить покупку</p>
                    </div>
                }
                {/** диалоговое окно авторизации */}
                {step == 2 &&
                    <div className="wrap-login" >
                        <h2 className="login-header-log-in">ВХОД В ЛИЧНЫЙ КАБИНЕТ <hr></hr></h2>
                        <div className="input-container">
                            <label htmlFor="loginEmail">Логин или Email</label>
                            <input type="text" id="loginEmail" onChange={handleLoginEmailChange} placeholder="Логин или Email" />
                        </div>
                        <div className="input-container">
                            <label htmlFor="password">Пароль</label>
                            <input type="password" id="password" onChange={handlePasswordChange} placeholder="*********" />
                        </div>
                        <button className="log-in-cl-kk-log-in" onClick={LogIn}>Войти в профиль</button>
                        <div className="checkbox-forgot-log-in">
                            <div className="checkbox-div">
                                <div onClick={rememberMeChange} className={isRememberMeState ? "customCheckboxContainerActive" : "customCheckboxContainer"} id="rememberMe"></div>
                                <label htmlFor="rememberMe">Запомнить меня</label>
                            </div>
                            <a href="#" className="forgot-password-log-in">Забыли пароль?</a>
                        </div>
                    </div>
                }
                {/** диалоговое окно оплата комиссии */}
                {step == 3 &&
                    <div className="wrap-step wrap-step2 buyNowSte2" >
                        <h1 className="header-text-cl-kk">Оплата комиссии <hr></hr> </h1>
                        <div className="Balls-close">
                            <span className="ball-1 b-1-comp"></span>
                            <span className="line-1 l-1-comp"></span>
                            <span className="ball-2 b-1-comp"></span>
                            <span className="line-2"></span>
                            <span className="ball-3"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Оплата комиссии</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Оплата лота</p>
                            </div>
                        </div>
                        <div className="center flex-dircol-gap">
                            <button className="log-in-cl-kk" onClick={payComission}>Оплатить платёжкой</button>
                            <button className="log-in-cl-kk" onClick={payQRComission}>Оплатить QR-кодом</button>

                            <p className="text-good-r text-good-pay long-text-good-pay">Вам осталось оплатить комиссию площадки.
                                После оплаты комиссии станет доступна оплата лота.</p>

                            <p className="text-good-r text-good-pay long-text-good-pay">
                                Чтобы завершить покупку, зайдите в приложение своего банка и оплатите два платежных поручения, которые открылись в новых вкладках браузера.
                                Платежные данные находятся в этих поручениях.
                                Покупка будет подтверждена в течение трех рабочих дней после поступления средств.</p>

                        </div>
                    </div>
                }

                {/** диалоговое окно оплата комиссии через QR код */}
                {step == 31 &&
                    <div className="wrap-step wrap-step2 buyNowSte2" >
                        <h1 className="header-text-cl-kk">Оплата комиссии <hr></hr> </h1>
                        {payQRcode.Img.length > 0 && errorPay.length == 0 ?
                            <div className="center flex-dircol-gap">
                                {payQRcode.Img &&
                                    <img src={payQRcode.Img}></img>
                                }

                                <table className="paytable">
                                    <tr className="paytabletr">
                                        <td>Получатель платежа</td><td>{payQRcode.Name}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Расчётный счет</td><td>{payQRcode.PersonalAcc}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>ИНН получателя</td><td>{payQRcode.PayeeINN}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>КПП получателя</td><td>{payQRcode.KPP}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Наименование банка</td><td>{payQRcode.BankName}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>БИК банка</td><td>{payQRcode.BIC}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Корр. счет</td><td>{payQRcode.CorrespAcc}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Назначение платежа</td><td>{payQRcode.Purpose}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Сумма платежа</td><td>{payQRcode.SumRub} рублей</td>
                                    </tr>
                                </table>

                                <button className="log-in-cl-kk" onClick={check_payQRComission}>Проверить оплату</button>

                                <p className="text-good-r text-good-pay long-text-good-pay">Вам осталось оплатить комиссию площадки.
                                    Для оплаты по QR-коду откройте приложение мобильного банка, выберите раздел "Платежи"
                                    и пункт "Оплата по QR". Наведите камеру на QR-код, удостоверившись, что окно приложения совпадает со штрих-кодом.
                                    После сканирования проверьте заполненные поля и выполните оплату.</p>
                            </div> : errorPay.length > 0 &&
                            <div className="center flex-dircol-gap">
                                <p className="text-good-r text-good-pay long-text-good-pay">Упс, произошла непредвиденная ошибка...
                                    <br />Просьба обратиться в службу технической поддержки
                                    <br />(<a className="text-good-r text-good-pay long-text-good-pay" href="tel:+74951473765">+7 (495) 147-37-65</a>)
                                    <br />со следующим сообщением: </p>
                                <p className="text-good-r text-good-pay long-text-good-pay">{errorPay}</p>
                            </div>

                        }
                    </div>
                }

                {/** диалоговое окно оплата лота */}
                {step == 4 &&
                    <div className="wrap-step wrap-step3">
                        <h1 className="header-text-cl-kk">Оплата лота <hr></hr></h1>
                        <div className="Balls-close">
                            <span className="ball-1 b-1-comp"></span>
                            <span className="line-1 l-1-comp"></span>
                            <span className="ball-2 b-2-comp"></span>
                            <span className="line-2 l-2-comp"></span>
                            <span className="ball-3 b-1-comp"></span>
                        </div>
                        <div className="step-indicator">
                            <div className="cl-kk bl-1-f-1">
                                <p className="step">шаг 1</p>
                                <p className="text-cl-kk">Авторизация пользователя</p>
                            </div>
                            <div className="cl-kk bl-1-f-2">
                                <p className="step">шаг 2</p>
                                <p className="text-cl-kk">Оплата комиссии</p>
                            </div>
                            <div className="cl-kk bl-1-f-3">
                                <p className="step">шаг 3</p>
                                <p className="text-cl-kk">Оплата лота</p>
                            </div>
                        </div>
                        <div className="center flex-dircol-gap">
                            <button className="log-in-cl-kk" onClick={payLot}>Оплатить платёжкой</button>
                            <button className="log-in-cl-kk" onClick={payQRLot}>Оплатить QR-кодом</button>
                        </div>
                    </div>
                }

                {/** диалоговое окно оплата лота через QR код */}
                {step == 41 && (
                    <div className="wrap-step wrap-step2 buyNowSte2">
                        <h1 className="header-text-cl-kk">Оплата лота <hr></hr> </h1>
                        {payQRcode.Img.length > 0 && errorPay.length == 0 ? (
                            <div className="center flex-dircol-gap">
                                {payQRcode.Img &&
                                    <img src={payQRcode.Img}></img>
                                }

                                <table className="paytable">
                                    <tr className="paytabletr">
                                        <td>Получатель платежа</td><td>{payQRcode.Name}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Расчётный счет</td><td>{payQRcode.PersonalAcc}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>ИНН получателя</td><td>{payQRcode.PayeeINN}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>КПП получателя</td><td>{payQRcode.KPP}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Наименование банка</td><td>{payQRcode.BankName}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>БИК банка</td><td>{payQRcode.BIC}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Корр. счет</td><td>{payQRcode.CorrespAcc}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Назначение платежа</td><td>{payQRcode.Purpose}</td>
                                    </tr>
                                    <tr className="paytabletr">
                                        <td>Сумма платежа</td><td>{payQRcode.SumRub} рублей</td>
                                    </tr>
                                </table>

                                <button className="log-in-cl-kk" onClick={check_payQRLot}>Проверить оплату</button>
                                <p className="text-good-r text-good-pay long-text-good-pay">Вам осталось оплатить комиссию площадки.
                                    Для оплаты по QR-коду откройте приложение мобильного банка, выберите раздел "Платежи"
                                    и пункт "Оплата по QR". Наведите камеру на QR-код, удостоверившись, что окно приложения совпадает со штрих-кодом.
                                    После сканирования проверьте заполненные поля и выполните оплату.</p>
                            </div>) : errorPay.length > 0 &&
                        (<div className="center flex-dircol-gap">
                            <p className="text-good-r text-good-pay long-text-good-pay">Упс, произошла непредвиденная ошибка...
                                <br />Просьба обратиться в службу технической поддержки
                                <br />(<a className="text-good-r text-good-pay long-text-good-pay" href="tel:+74951473765">+7 (495) 147-37-65</a>)
                                <br />со следующим сообщением: </p>
                            <p className="text-good-r text-good-pay long-text-good-pay">{errorPay}</p>
                        </div>)
                        }

                    </div>
                )}
                {step == 5 &&
                    <div className="paylotsuccess">
                        <h4>Спасибо за покупку! <hr></hr> </h4>
                        <p>Поздравляем, вы успешно оплатили лот.</p>
                    </div>
                }
            </div>
            <div className="cdekMapWrap" style={{ display: (step === 6 && props.shippingMethod == 'Курьерская доставка') ? 'none' : 'none' }}>
                <div id="cdek-map"></div>
            </div>
            {/*<div className="cdekMapWrap" style={{ display: (step == 5 && props.shippingMethod == 'Курьерская доставка') ? 'flex' : 'none' }}>*/}
            {/*    <div id="cdek-map"></div>*/}
            {/*</div>*/}
        </React.Fragment>
    );
}

export default PopupBuyNow;